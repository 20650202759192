// @flow
import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Image from '../components/Image';
import Slideshow from '../components/Slideshow';
import '../css/project.scss';

const IndexPage = (props) => {
	const data = useStaticQuery(graphql`
		query FeaturedProjects {
			allProject {
				nodes {
					feature
					slideshow
					name
					location
					slug
					projects {
						slug
						featured {
							border
							src
						}
						gallery {
							border
							src
						}
					}
				}
			}
			allImageSharp(filter: {fluid: {src: {regex: "/home-/"}}}, sort: {fields: fluid___originalName, order: ASC}) {
				edges {
					node {
						id
						fluid(quality: 90, maxWidth: 1080) {
							base64
							srcWebp
							srcSetWebp
							originalImg
							originalName
							presentationWidth
							presentationHeight
							sizes
							src
							srcSet
							aspectRatio
						}
					}
				}
			}
		}
	`);

	const featured = data.allProject.nodes.filter(project => project.feature);
	const nonFeatured = data.allProject.nodes.filter(project => !project.feature);

	return (
		<Layout withFooter className={props.transitionStatus}>
			<SEO title="Creative Company" />
			<article className="separator separator--thick separator--bottom">
				<h1 className="type--center">
					Wir kreieren Stories, die Menschen und Marken verbinden.
				</h1>
			</article>
			<div className="list type--center">
				{featured.flatMap(client => {
					if (client.slideshow) {
						return (
							<div className="project" key={client.slug}>
								<span className="list__item">
									Für {client.name}
								</span>
								<Slideshow name={client.name} gallery={client.projects[0].gallery} />
							</div>
						);
					}

					return (
						<div className="project" key={client.slug}>
							<span className="list__item">
								Für {client.name}<br />
								{client.location}
							</span>
							{client.projects.map(project => {
								if (project.featured.src) {
									const result = data.allImageSharp.edges.find(image => {
										return image.node.fluid.originalName === project.featured.src;
									});

									if (result && result.node && result.node.fluid) {
										const slug = project.slug ? `projekte-detail/${client.slug}/${project.slug}` : `projekte/${client.slug}`;

										return (
											<Link key={slug} to={slug} className="project__link">
												<Image border={project.featured.border} fluid={result.node.fluid} />
											</Link>
										);
									}
								}

								return null;
							})}
						</div>
					);
				})}
			</div>
			<div className="list list--last type--center">
				{nonFeatured.map(project => (
					<div key={project.slug} className="list__item list__item--small">
						Für {project.name}<br />
						{project.location}
					</div>
				))}
			</div>
		</Layout>
	);
}

export default IndexPage;
