import React, { useCallback, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import useInterval from '../hooks/useInterval';
import '../css/slideshow.scss';

/**
 * Slideshow gallery
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Slideshow = (props) => {
	const data = useStaticQuery(graphql`
		query GalleryImages {
			allImageSharp(filter: {fluid: {src: {regex: "/slideshow-/"}}}, sort: {fields: fluid___originalName, order: ASC}) {
				edges {
					node {
						id
						fluid(quality: 100, maxWidth: 1080) {
							base64
							srcWebp
							srcSetWebp
							originalImg
							originalName
							presentationWidth
							presentationHeight
							sizes
							src
							srcSet
							aspectRatio
						}
						fixed(quality: 90) {
							base64
							srcWebp
							srcSetWebp
							src
							srcSet
							aspectRatio
						}
					}
				}
			}
		}
	`);

	const src = props.gallery.flatMap(gallery => gallery.map(img => img.src));
	const gallery = data.allImageSharp.edges.filter(image => {
		return src.includes(image.node.fluid.originalName);
	});

	const [currentIndex, setCurrentIndex] = useState(0);

	useInterval( () => {
		if (currentIndex < gallery.length - 1) {
			setCurrentIndex((currentIndex) => currentIndex + 1);
		} else {
			setCurrentIndex(0);
		}
	}, 2000);

	return (
		<div className="slideshow">
			{gallery.map((img, index) => {
				const className = currentIndex === index ? 'slideshow__image slideshow__image--current' : 'slideshow__image';

				return (
					<div className={className} key={img.node.id} style={{
						zIndex: (gallery.length * 100) - index
					}}>
						<Img
							fluid={img.node.fluid}
							placeholderStyle={{ visibility: 'hidden' }}
							loading="eager"
						/>
					</div>
				);
			})}
		</div>
	);
};

export default Slideshow;
